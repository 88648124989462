import { cva, cx, VariantProps } from "class-variance-authority"
import Image from "next/image"
import Spinner from "src/views/components/UI/loaders/Spinner"
import { twMerge } from "tailwind-merge"

interface ButtonProps {
  className?: string
  text: string
  onClick?: any
  pointer?: boolean
  icon?: string
  type?: "button" | "submit" | "reset" | undefined
  height?: number
  svg?: any
  textClassName?: string
  width?: number
  disabled?: boolean
  loading?: boolean
  form?: string
}

const buttonStyles = cva("inline-flex items-center gap-1 cursor-pointer justify-center transition-all", {
  variants: {
    intent: {
      primary: "bg-primary-100 hover:bg-primary-90 text-white",
      secondary: "bg-white hover:bg-primary-5 outline outline-1 outline-primary-100 text-primary-100",
      tertiary: "bg-[#3C74FF] hover:opacity-90 text-white",
      ghost: "bg-white hover:bg-neutral-10 text-secondary-100",
      outline: "text-secondary-100 outline outline-1 outline-secondary-100 hover:bg-secondary-100 hover:text-white",
      outline_muted: "text-secondary-100 outline outline-1 outline-secondary-20 hover:outline-secondary-100",
      black: "bg-secondary-100 hover:bg-secondary-90 text-white ",
      text: "text-primary-100 hover:text-primary-90 hover:underline",
      default: "bg-neutral-20 hover:bg-neutral-20"
    },
    rounded: {
      full: "rounded-full",
      xl: "rounded-xl",
      lg: "rounded-lg"
    },
    // You must give same size property to textStyle also
    size: {
      huge: "px-6",
      big: "px-6",
      normal: "px-5",
      small: "px-4",
      none: "px-0"
    }
  },
  compoundVariants: [
    { intent: "ghost", size: ["huge", "big"], className: "px-5" },
    { intent: "text", className: "px-0" },
    { intent: "text", className: "px-0" }
  ],

  defaultVariants: {
    intent: "primary",
    rounded: "lg",
    size: "big"
  }
})

const textStyles = cva("leading-snug", {
  variants: {
    // You must give same size property to buttonStyles also
    size: {
      huge: "py-5 text-md",
      big: "py-3 text-2sm leading-5",
      normal: "py-2.5 text-sm",
      small: "py-2 text-sm",
      none: "py-0 text-sm"
    },
    weight: {
      bold: "font-tbcx-bold",
      medium: "font-tbcx-medium",
      regular: "font-tbcx-regular"
    }
  },
  defaultVariants: {
    size: "big",
    weight: "medium"
  }
})

interface Props extends ButtonProps, VariantProps<typeof buttonStyles>, VariantProps<typeof textStyles> {}

const Button = ({
  text,
  icon,
  type = "button",
  className,
  intent,
  rounded,
  weight,
  disabled = false,
  size,
  svg,
  width = 16,
  height = 16,
  loading,
  textClassName,
  onClick,
  form
}: Props) => {
  return (
    <button
      form={form}
      onClick={onClick}
      className={twMerge(
        cx(
          buttonStyles({ intent, rounded, size }),
          className,
          loading && "pointer-events-none opacity-50",
          disabled && "pointer-events-none bg-neutral-20 text-secondary-50",
          disabled && intent === "text" && "pointer-events-none bg-transparent text-secondary-50"
        )
      )}
      type={type}
      disabled={disabled || loading}
    >
      {svg && svg}
      {icon && <Image src={icon} alt={icon} width={width} height={height} className="-ml-[5px]" />}

      <span className={twMerge(cx(textStyles({ weight, size }), textClassName))}>{text}</span>
      {loading && <Spinner />}
    </button>
  )
}

export default Button
