// Full domain URL excluding leafs
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import { AUTH_URL } from "src/env"

const useOriginUrl = () => {
  const router = useRouter()

  const [originUrl, setOriginUrl] = useState<string>("")
  const [tnetAuthUrlWithCallback, setTnetAuthUrlWithCallback] = useState<string>("")

  useEffect(() => {
    if (typeof window !== "undefined") {
      setOriginUrl(window.location.origin)
    }
  }, [])

  useEffect(() => {
    setTnetAuthUrlWithCallback(`${AUTH_URL}${originUrl}${router.asPath}`)
  }, [originUrl, router.asPath])

  const initiateLogin = useCallback(() => {
    if (typeof window !== "undefined" && originUrl && router.asPath) {
      location.replace(tnetAuthUrlWithCallback)
    }
  }, [originUrl, router.asPath, tnetAuthUrlWithCallback])

  return { originUrl, tnetAuthUrlWithCallback, initiateLogin }
}

export default useOriginUrl
